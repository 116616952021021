import api from '../plugins/axios'

const routePath = "usuarios"

export default {
    async findAll(){
        return await api.get(routePath)
    },
    async create(usuario){
        return await api.post(routePath,usuario)
    }
}