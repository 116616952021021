<template>
  <div class="app-container">
    <v-stepper alt-labels v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Dados Pessoais
        </v-stepper-step>

        <v-divider active></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Endereço </v-stepper-step>

        <v-divider :active="e1 == 3"></v-divider>

        <v-stepper-step step="3"> Senha </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <Input
              v-model="formOne.nome"
              :error="$v.formOne.nome.$error"
              type="text"
              label="Nome completo"
              id="nome"
            >
            </Input>
            <Input
              v-model="formOne.apelido"
              :error="$v.formOne.apelido.$error"
              label="Como quer ser chamado?"
              id="apelido"
            >
            </Input>
            <Input
              v-model="formOne.telefone"
              :error="$v.formOne.telefone.$error"
              type="text"
              label="Telefone"
              :mask="'(##) # ####-####'"
              id="telefone"
            >
            </Input>
            <Input
              v-model="data_nascimento"
              :error="$v.formOne.data_nascimento.$error"
              type="date"
              label="Data de nascimento"
              :max="dataHoje"
              :errorMsg="'Data Inválida'"
              id="nascimento"
            >
            </Input>
            <Links to="/login" label="Já tenho uma conta"></Links>
          </v-card>

          <div style="margin: 30px 0; display: flex; justify-content: end">
            <ButtonFilled label="Prosseguir" :onClick="forward"></ButtonFilled>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <Input
              type="text"
              v-model="formTwo.endereco.cep"
              :error="$v.formTwo.endereco.cep.$error"
              label="CEP"
              :mask="'#####-###'"
              id="cep"
            >
            </Input>
            <Input
              type="text"
              v-model="formTwo.endereco.rua"
              :error="$v.formTwo.endereco.rua.$error"
              label="Rua"
              id="rua"
            >
            </Input>
            <Input
              type="text"
              v-model="formTwo.endereco.bairro"
              :error="$v.formTwo.endereco.bairro.$error"
              label="Bairro"
              id="bairro"
            >
            </Input>
            <Input
              type="text"
              v-model="formTwo.endereco.cidade"
              :error="$v.formTwo.endereco.cidade.$error"
              label="Cidade"
              id="cidade"
            >
            </Input>
            <Select
              v-model="formTwo.endereco.estado"
              :error="$v.formTwo.endereco.estado.$error"
              :options="estados"
              id="estado"
            >
            </Select>
          </v-card>

          <div
            style="
              margin: 30px 0;
              display: flex;
              justify-content: space-between;
            "
          >
            <ButtonBack :onClick="backPage"> Cancel </ButtonBack>

            <ButtonFilled label="Prosseguir" :onClick="forward"></ButtonFilled>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-1" height="200px">
            <Input
              v-model="formThree.email"
              :error="$v.formThree.email.$error"
              label="Email"
              id="email"
            >
            </Input>
            <Input
              v-model="formThree.senha"
              :error="$v.formThree.senha.$error"
              type="password"
              label="Senha"
              id="senha"
            >
            </Input>
            <Input
              v-model="formThree.confirmPassword"
              :error="$v.formThree.confirmPassword.$error"
              type="password"
              label="Confirme sua senha"
              :errorMsg="'As senhas devem ser iguais'"
              id="confirmSenha"
            ></Input>
          </v-card>

          <div style="display: flex; justify-content: space-between">
            <ButtonBack :onClick="backPage"> Cancel </ButtonBack>
            <ButtonFilled
              label="Criar Conta"
              :onClick="CreateAccount"
            ></ButtonFilled>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ButtonFilled from "../components/common/Button/ButtonFilled.vue";
import ButtonBack from "../components/common/Button/ButtonBack.vue";
import Links from "../components/common/Links/Links.vue";
import Input from "../components/common/Input/Input.vue";
import Select from "../components/common/Select/Select.vue";
import Usuario from "../services/usuario";
import BuscaCep from "../services/BuscaCep.js";

import { required, email, sameAs, maxValue } from "vuelidate/lib/validators";

export default {
  nome: "Register",
  components: {
    ButtonBack,
    ButtonFilled,
    Links,
    Input,
    Select,
  },
  data() {
    return {
      e1: 1,
      data_nascimento: "",
      formOne: {
        nome: "",
        apelido: "",
        telefone: "",
        data_nascimento: "",
      },
      formTwo: {
        endereco: {
          cep: "",
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
        },
      },
      formThree: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      estados: [
        { value: "", text: "Estado", disabled: "disabled", hidden: "hidden" },
        { value: "AC", text: "AC" },
        { value: "AL", text: "AL" },
        { value: "AM", text: "AM" },
        { value: "AP", text: "AP" },
        { value: "BA", text: "BA" },
        { value: "CE", text: "CE" },
        { value: "DF", text: "DF" },
        { value: "ES", text: "ES" },
        { value: "GO", text: "GO" },
        { value: "MA", text: "MA" },
        { value: "MG", text: "MG" },
        { value: "MT", text: "MT" },
        { value: "MS", text: "MS" },
        { value: "PA", text: "PA" },
        { value: "PB", text: "PB" },
        { value: "PE", text: "PE" },
        { value: "PI", text: "PI" },
        { value: "PR", text: "PR" },
        { value: "RJ", text: "RJ" },
        { value: "RN", text: "RN" },
        { value: "RO", text: "RO" },
        { value: "RS", text: "RS" },
        { value: "RR", text: "RR" },
        { value: "SE", text: "SE" },
        { value: "SC", text: "SC" },
        { value: "SP", text: "SP" },
        { value: "TO", text: "TO" },
      ],
    };
  },
  validations: {
    formOne: {
      nome: { required },
      apelido: { required },
      telefone: { required },
      data_nascimento: {
        required,
        maxValue: maxValue(new Date()),
      },
    },
    formTwo: {
      endereco: {
        cep: { required },
        rua: { required },
        bairro: { required },
        cidade: { required },
        estado: { required },
      },
    },
    formThree: {
      email: { required, email },
      senha: { required },
      confirmPassword: { required, sameAsPassword: sameAs("senha") },
    },
  },
  mounted() {
    this.disableAutoComplete();
  },
  watch: {
    data_nascimento() {
      this.formOne.data_nascimento = new Date(this.data_nascimento);
    },
    "formTwo.endereco.cep": {
      async handler(newValue) {
        if (newValue.length > 8) {
         let busca = await this.buscaCep(newValue);
          console.log(busca);
          this.formTwo.endereco.rua = busca.logradouro
          this.formTwo.endereco.bairro = busca.bairro
          this.formTwo.endereco.cidade = busca.localidade
          this.formTwo.endereco.estado = busca.uf
          console.log(this.formTwo);
        }
      },
      deep: true,
    },
  },
  computed: {
    dataHoje() {
      const today = new Date();
      return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
        -2
      )}-${("0" + today.getDate()).slice(-2)}`;
    },
  },
  methods: {
    async buscaCep(cep) {
      let resultado = await BuscaCep(cep);
      return resultado
    },
    disableAutoComplete() {
      const elements = document.querySelectorAll('[autocomplete="off"]');

      if (!elements) {
        return;
      }

      elements.forEach((element) => {
        element.setAttribute("readonly", "readonly");

        window.addEventListener("load", () => {
          setTimeout(() => {
            element.removeAttribute("readonly");
          }, 500);
        });
      });
    },
    backPage() {
      this.e1--;
    },
    forward() {
      switch (this.e1) {
        case 1:
          this.$v.formOne.$touch();
          if (this.$v.formOne.$invalid) {
            this.$toast.error("Verifique todos os campos.", {
              timeout: 2000,
            });

            return;
          }
          break;
        case 2:
          this.$v.formTwo.$touch();
          if (this.$v.formTwo.$invalid) {
            this.$toast.error("Verifique todos os campos.", {
              timeout: 2000,
            });

            return;
          }
          break;
        case 3:
          this.$v.formThree.$touch();
          if (this.$v.formThree.$invalid) {
            this.$toast.error("Verifique todos os campos.", {
              timeout: 2000,
            });

            return;
          }
          break;
        default:
          break;
      }

      if (this.e1 == 3) {
        this.e1 = 1;
      } else {
        this.e1++;
      }
    },
    CreateAccount() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$toast.error("Ops, houve um erro.", {
          timeout: 2000,
        });

        return;
      }

      Usuario.create({
        ...this.formOne,
        ...this.formTwo,
        ...this.formThree,
      })
        .then(() => {
          this.$router.push("/login");
        })
        .catch((err) => {
          this.$toast.error(
            err.response.data
              ? err.response.data.mensagem
              : "Erro ao cadastrar usuário",
            {
              timeout: 2000,
            }
          );
        });
    },
  },
};
</script>

<style lang="scss">
.v-stepper__header {
  box-shadow: 0 0 0 0 !important;
  height: 20vh !important;
  align-items: center !important;
}

.v-stepper__step {
  position: static !important;
}

.v-stepper__step__step {
  background: #009987;
  position: static;
}

.v-divider {
  align-self: center !important;
  margin: -60px !important;
  margin-bottom: -50px !important;
}

.v-divider[active] {
  background: #009987;
  align-self: center !important;
  margin: -60px !important;
  margin-bottom: -50px !important;
}

.v-stepper__step .v-stepper__step--inactive {
  background: #888888;
}

.v-stepper__label {
  display: block !important;
  position: absolute !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #009987 !important;
  top: 3%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.v-stepper__step--inactive .v-stepper__label,
.v-stepper__step--complete .v-stepper__label {
  display: none !important;
}

.v-stepper__items {
  height: 85vh;
}

.v-stepper__content,
.v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-card {
  flex: 1;
  text-align: center;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-stepper {
  .input-wrapper {
    margin-bottom: 15px;
  }

  .select {
    margin-bottom: 15px;
  }

  .btn-filled {
    width: 200px !important;
  }
}

@media screen and (min-width: 700px) {
  .v-stepper {
    width: 500px !important;
  }
  .v-stepper__header {
    box-shadow: 0 0 0 0 !important;
    height: 20vh !important;
    align-items: center !important;
  }
  .v-stepper__wrapper {
    
  }
  .v-stepper__items {
    height: fit-content;
  }
}
</style>