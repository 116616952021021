<template>
  <button @click.prevent="onClick" class="button" :disabled="disabled">
      <div v-if="isLoading">
          <div class="loading"></div>
      </div>
      <div v-else>
        <img src="@/assets/icons/Forward.svg" alt="">
      </div>
  </button>
</template>

<script>
export default {
    props: ['Label', 'isLoading', 'onClick', 'disabled']
}
</script>

<style scoped>
    @keyframes loading {
        from {
            transform: rotate(0def);
        }
        to {
            transform:  rotate(360deg);
        }
    }

    .button {
        background: #fff !important;
        border-radius: 40px;
        border: 2px solid #FF7FA8 !important;
        width: 54px;
        height: 54px;
        font-size: 16px;
        color: #FF7FA8 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button:focus {
        box-shadow: 3px 3px 3px #F2CBD7;
    }

    .loading {
        border: 2px solid #FF7FA8;
        border-bottom: 2px solid transparent;
        width: 20px;
        height: 20px;
        animation: loading 1s linear infinite;
        border-radius: 50%;
    }
    
    .button:disabled {
        border: 2px solid #FFB2CB;
        color: #FFB2CB;
    }
</style>