import axios from "axios";

export default async function BuscaCep(cep) {
    try {
        let resuldatoCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        return resuldatoCep.data
    } catch (error) {
        return error
    }

}